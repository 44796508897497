<template>
  <DetailSlot>
    <template #default="{formDesign, formDetail}">
      <formViews ref="formDetail" v-if="formDetail.formId" style="width: 100%" :formDesign="formDesign" @tab-change="onTabChange"></formViews>
    </template>
  </DetailSlot>
</template>
<script>
import formViews from "./views.vue";
import { mapState } from "vuex";
import DetailSlot from "./detail-slot.vue";
export default {
  components: {
    DetailSlot,
    formViews,
  },
  data () {
    return {
      tabName: "",
    };
  },
  methods: {
    onTabChange (index, name) {
      // index=3 tabName为个人填报，4为审核列表，5为管理列表，8为统计报表
      switch (index) {
        case 1:
          this.tabName = "";
          break;
        case 3:
          this.tabName = "已填列表";
          break;
        case 4:
          this.tabName = "审核列表";
          break;
        case 5:
          this.tabName = "管理列表";
          break;
        case 8:
          this.tabName = "统计报表";
          break;
        default:
          this.tabName = name;
          break;
      }
    }
  },
};
</script>

<style lang="scss">
[v-cloak] {
  display: none;
}

.detail-nav {
  width: 100%;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  background: #fff;
  height: 56px;
  -webkit-transform: translateZ(0);
  margin: auto;
  text-align: center;
  box-shadow: 0 2px 4px 0 rgba(51, 45, 64, 0.1);
  -webkit-box-shadow: 0 2px 4px 0 rgba(51, 45, 64, 0.1);

  .detail-container {
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
  }

  .detail-left {
    display: flex;
    flex-direction: row;
    align-items: center;
    line-height: 56px;
    font-size: 14px;
    color: #333333;

    .detail-left-logo {
      cursor: pointer;
      font-size: 20px;
      margin-left: 10px;
    }

    .separator {
      width: 1px;
      height: 20px;
      border-right: 1px solid #dbdde7;
      margin: 0 16px;
    }

    .headerNow {
      font-size: 18px;
      font-weight: 500;
      cursor: pointer;
    }
  }
}

.form-detail-container {
  height: 100%;
  position: absolute;
  padding-top: 0;
  margin-top: 20px;
  -webkit-box-shadow: none;
  box-shadow: none;
  top: 60px;
  bottom: 10px;
  right: 0px;
  left: 0px;
  border-radius: 0;

  .forms-content {
    height: 100%;
    width: 100%;
    max-width: 100%;
    margin: 0px auto;
    background: #ffffff;
    padding: 0 10px;
    border-radius: 4px;
    box-sizing: border-box;
  }
}
</style>
