var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("DetailSlot", {
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function (ref) {
          var formDesign = ref.formDesign
          var formDetail = ref.formDetail
          return [
            formDetail.formId
              ? _c("formViews", {
                  ref: "formDetail",
                  staticStyle: { width: "100%" },
                  attrs: { formDesign: formDesign },
                  on: { "tab-change": _vm.onTabChange },
                })
              : _vm._e(),
          ]
        },
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }