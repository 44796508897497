<template>
  <div class="headMain">
    <div class="headBox">
      <div class="leftBox" @click="toBaseView">
        <img :src="agencyLog" alt=""  style="height:40px;width:unset;"/>
        <div class="leftBox-title" v-html="pageTitle">
          <!-- 汇创青春大赛<br />赛事管理平台 -->
        </div>
      </div>
      <div class="rightBox">
        <div>
        </div>
        <div class="loginlogo" @click="toBaseView">
          <TitleUserMenu />
          <!-- <img :src="require('@/assets/webview/img/huilogo.png')" alt="" /> -->
        </div>
      </div>
    </div>
  </div>
</template>
<script>

import { mapState } from "vuex";
import TitleUserMenu from "@/components/TitleUserMenu/index.vue";

export default {
  props:{
    formDesign:{
      type:Object,
      default: ()=>{}
    }
  },
  computed:{
    ...mapState({
      agencyLog: (state) => state.designView.agencyLog,
    }),
    pageTitle: function(){
      return (this.formDesign.pageTitle||'').replace('\n','<br>')
    }
  },
  components: {
    TitleUserMenu
  },
  data(){
    return {
      search: ''
    }
  },
  methods:{
    toBaseView(){
      this.$store.dispatch('EnableDesignView','baseView')
    }
  }
}
</script>
<style lang="scss" scoped>
@media (min-width: 768px) {
  .headMain {
    box-sizing: border-box;
    padding: 10px 40px 10px 10px;
    width: 100%;
    // background: linear-gradient(to bottom, rgba(255,255,255,1) 30%, rgba(255,255,255,0.8) 90%, transparent);
    background:white;
    position:fixed;
    left:0px;
    right:0px;
    top:0px;
    z-index: 1000;
    .headBox {
      box-sizing: border-box;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .leftBox {
        display: flex;
        align-items: center;
        img {
          width: 300px;
          display: block;
        }
        .leftBox-title {
          font-style: italic;
          font-size: 28px;
          font-weight: bold;
          letter-spacing: 4px;
          margin-left: 20px;
          text-align: left;
        }
      }
      .rightBox {
        display: flex;
        align-items: center;
        .searchBox {
        }
        .loginlogo {
          margin-left: 40px;
          img {
            width: 100px;
            cursor: pointer;
          }
        }
      }
    }
  }
}
</style>
