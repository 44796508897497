var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "swiperBox" },
    [
      _c("img", {
        staticClass: "banner",
        attrs: { src: _vm.imagePlaceHolder, width: "100%" },
      }),
      _c(
        "el-carousel",
        {
          staticClass: "swiperBox-m",
          attrs: { loop: "", interval: 4000, height: "100%" },
        },
        _vm._l(_vm.formDesign.bannerFiles, function (item, index) {
          return _c("el-carousel-item", {
            key: index,
            staticClass: "swiperBox-m-01",
            style: "background-image: url(" + item.url + ")",
          })
        }),
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }