var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {},
    [
      _vm.viewName === "baseView"
        ? [
            _c("detail-nav", {
              attrs: { breadcrumb: _vm.breadcrumb },
              on: {
                naviTo1: _vm.naviHome,
                naviTo2: _vm.onBackDetail,
                "login-success": _vm.onLoginSuccess,
              },
            }),
            false
              ? _c("div", { staticClass: "detail-nav" }, [
                  _c(
                    "div",
                    { staticClass: "detail-container app-inner-container" },
                    [
                      _c("div", { staticClass: "detail-left" }, [
                        _c(
                          "div",
                          {
                            staticClass: "detail-left-logo",
                            on: { click: _vm.naviHome },
                          },
                          [_c("svg-icon", { attrs: { "icon-class": "home" } })],
                          1
                        ),
                        _c("div", { staticClass: "separator" }),
                        _c(
                          "span",
                          {
                            staticClass: "headerNow",
                            on: { click: _vm.onBackDetail },
                          },
                          [
                            _vm._v(
                              _vm._s(_vm.formDetail && _vm.formDetail.name)
                            ),
                          ]
                        ),
                        _vm.tabName
                          ? _c("div", { staticClass: "separator" })
                          : _vm._e(),
                        _vm.tabName
                          ? _c("span", { staticClass: "headerNow" }, [
                              _vm._v(_vm._s(_vm.tabName)),
                            ])
                          : _vm._e(),
                      ]),
                      _c("title-user-menu", {
                        attrs: {
                          "name-color": "#2B344199",
                          "icon-color": "#2B344199",
                        },
                      }),
                    ],
                    1
                  ),
                ])
              : _vm._e(),
            _c(
              "div",
              {
                staticClass: "app-container form-detail-container",
                staticStyle: { overflow: "hidden" },
              },
              [
                _c(
                  "div",
                  { staticClass: "forms-content" },
                  [
                    _vm._t("default", null, {
                      formDesign: _vm.formDesign,
                      formDetail: _vm.formDetail,
                    }),
                  ],
                  2
                ),
              ]
            ),
          ]
        : _vm._e(),
      _vm.viewName === "webView"
        ? [
            _c("WebViewHeader", {
              attrs: { formDesign: _vm.formDesign, formDetail: _vm.formDetail },
            }),
            _c("WebViewBanner", {
              attrs: { formDesign: _vm.formDesign, formDetail: _vm.formDetail },
            }),
            _vm._t("default", null, {
              formDesign: _vm.formDesign,
              formDetail: _vm.formDetail,
            }),
            _vm._l(_vm.formDesign.extra, function (item, index) {
              return [
                item.type == "richtext"
                  ? _c("WebViewRich", {
                      directives: [
                        {
                          name: "key",
                          rawName: "v-key",
                          value: index,
                          expression: "index",
                        },
                      ],
                      attrs: {
                        value: item,
                        formDesign: _vm.formDesign,
                        projectDetail: _vm.formDetail,
                      },
                    })
                  : _vm._e(),
                item.type == "video"
                  ? _c("WebViewVideo", {
                      directives: [
                        {
                          name: "key",
                          rawName: "v-key",
                          value: index,
                          expression: "index",
                        },
                      ],
                      attrs: {
                        value: item,
                        formDesign: _vm.formDesign,
                        projectDetail: _vm.formDetail,
                      },
                    })
                  : _vm._e(),
              ]
            }),
            _c("WebViewFooter", {
              attrs: { formDesign: _vm.formDesign, formDetail: _vm.formDetail },
            }),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }