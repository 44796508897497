<template>
  <div v-cloak>
    <template v-if="viewName === 'baseView'">
      <detail-nav :breadcrumb="breadcrumb" @naviTo1="naviHome" @naviTo2="onBackDetail" @login-success="onLoginSuccess"></detail-nav>
      <div class="detail-nav" v-if="false">
        <div class="detail-container app-inner-container">
          <div class="detail-left">
            <div class="detail-left-logo" @click="naviHome">
              <svg-icon icon-class="home" />
            </div>
            <div class="separator"></div>
            <span class="headerNow" @click="onBackDetail">{{
              formDetail && formDetail.name
            }}</span>

            <div class="separator" v-if="tabName"></div>
            <span class="headerNow" v-if="tabName">{{ tabName }}</span>
          </div>
          <title-user-menu name-color="#2B344199" icon-color="#2B344199"></title-user-menu>
        </div>
      </div>
      <div class="app-container form-detail-container" style="overflow: hidden">
        <div class="forms-content">
          <slot :formDesign="formDesign" :formDetail="formDetail"></slot>
        </div>
      </div>
    </template>
    <template v-if="viewName === 'webView'">
      <WebViewHeader :formDesign="formDesign" :formDetail="formDetail"></WebViewHeader>
      <WebViewBanner :formDesign="formDesign" :formDetail="formDetail"></WebViewBanner>
      <slot :formDesign="formDesign" :formDetail="formDetail"></slot>

      <template v-for="(item, index) in formDesign.extra">
        <WebViewRich :value="item" :formDesign="formDesign" :projectDetail="formDetail" v-if="item.type == 'richtext'" v-key="index"></WebViewRich>
        <WebViewVideo :value="item" :formDesign="formDesign" :projectDetail="formDetail" v-if="item.type == 'video'" v-key="index"></WebViewVideo>
      </template>
      <WebViewFooter :formDesign="formDesign" :formDetail="formDetail"></WebViewFooter>
    </template>
  </div>
</template>
<script>
import { mapState } from "vuex";
import * as formApi from "@/api/system/form";

import TitleUserMenu from "@/components/TitleUserMenu/index.vue";
import WebViewHeader from "@/views/system/webView/form/header.vue";
import WebViewBanner from "@/views/system/webView/form/banner.vue";
import WebViewFooter from "@/views/system/webView/footer.vue";


import WebViewRich from "@/views/system/webView/content-rich";
import WebViewVideo from "@/views/system/webView/content-video";
// 1-应用 2-表单
import { saveFormDesign, getFormDesign } from "@/api/system/designView";

import DetailNav from '@/components/DetailNav/index.vue'
export default {
  components: {
    TitleUserMenu,
    //-------------------
    WebViewBanner,
    WebViewHeader,
    WebViewFooter,

    WebViewRich,
    WebViewVideo,

    DetailNav
  },
  props: {
  },
  computed: {
    ...mapState({
      viewName: (state) => state.designView.viewName,
    }),
    breadcrumb:function(){
      return [
        this.formDetail.name,
        this.tabName
      ]
    }
  },
  provide() {
    return {
      getFormDetailObj: ()=> this.formDetail
    }
  },
  data () {
    return {
      formId: "",
      tabName: "",
      formDetail: {},
      formDesign: {},
    };
  },
  async created () {
    this.formId = this.decode(this.$route.params.id);
    await this.getFormDetail();
    this.getGetDesign();
  },
  methods: {
    onBackDetail () {
      // this.$router.go(-1)
      this.$refs.formDetail.$refs.formDetail.onTabClick(1);
    },
    naviHome () {
      if (this.$router.options.from.path === "/login") {
        this.$router.push({
          path: "/index",
        });
      } else {
        this.$router.go(-1);
      }
    },

    getFormDetail () {
      return new Promise((resolve) => {
        formApi.formDetail(this.formId).then((response, request) => {
          this.formDetail = response.data;
          console.log('detail-slot.vue', this.formDetail)

          this.$store.dispatch(
            "EnableDesignView",
            this.formDetail.enableDesign ? "webView" : "baseView"
          );
          resolve();
        });
      });
    },
    getGetDesign () {
      // 1-应用 2-表单
      if (this.formDetail.projectId != "0") {
        Promise.all([
          getFormDesign("1", this.formDetail.projectId),
          getFormDesign("2", this.formDetail.formId),
        ]).then((res) => {
          res[0].fieldConfig.forEach((x) => {
            this.$set(this.formDesign, x.type, x.config);
          });
          res[1].fieldConfig.forEach((x) => {
            if (x.config) {
              this.$set(this.formDesign, x.type, x.config);
            }
          });
        });
      } else {
        getFormDesign("2", this.formDetail.formId).then((res) => {
          res.fieldConfig.forEach((x) => {
            this.$set(this.formDesign, x.type, x.config);
          });
        });
      }
    },
    onLoginSuccess(){
      // 刷新页面
      this.$router.push({
        path: "/form/detail/" + this.encode(this.formId),
      });
    }
  },
};
</script>
<style scoped></style>
