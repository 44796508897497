var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "headMain" }, [
    _c("div", { staticClass: "headBox" }, [
      _c("div", { staticClass: "leftBox", on: { click: _vm.toBaseView } }, [
        _c("img", {
          staticStyle: { height: "40px", width: "unset" },
          attrs: { src: _vm.agencyLog, alt: "" },
        }),
        _c("div", {
          staticClass: "leftBox-title",
          domProps: { innerHTML: _vm._s(_vm.pageTitle) },
        }),
      ]),
      _c("div", { staticClass: "rightBox" }, [
        _c("div"),
        _c(
          "div",
          { staticClass: "loginlogo", on: { click: _vm.toBaseView } },
          [_c("TitleUserMenu")],
          1
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }